$(document).ready(function () {
    initSelects();
    initItemCarousels();
    initLayout();
    init_map();
});

window.onload = function(){
    init_map();
}

function resize() {
    initLayout();
}

var timo;
window.onresize = function () {
    clearTimeout(timo);
    timo = setTimeout(resize, 100);
};

$('.navbar-toggler').click(function () {
    $(this).toggleClass('active');
});

function initSelects() {
    var selects = $('select.styled:not(.done)');

    selects.each(function () {
        $(this).wrap('<div class="select-wrap"></div>');
        var wrap = $(this).parent();
        var selected = $(this).find(':selected');
        var def = $(this).find('[data-default="true"]');
        var notdefault = '';
        selected.attr('selected', true);

        if (selected.val() != def.val()) {
            notdefault = true;
        }
        wrap.append('<div class="select-value ' + (notdefault ? 'selected' : '') + '">' + selected.text() + '</div>');
        var html = '<div class="select-options">';
        $(this).find('option').each(function () {
            html += '<div class="option ' + ($(this).attr('selected') ? 'selected' : '') + '" data-value="' + $(this).val() + '" ' + ($(this).data('default') ? 'data-default="true"' : '') + '>' + $(this).text() + '</div>';
        });
        html += "</div>";

        wrap.append(html);
        wrap.addClass($(this).attr('class'));
        $(this).addClass('done');

        if ($('.select-options', wrap).outerHeight() >= parseInt($('.select-options', wrap).css('max-height'))) {
            $('.select-options', wrap).mCustomScrollbar();

        }
    });

    $('select.styled').click(function () {
        $(this).parent().focus().toggleClass('clicked');
    });

    $('.select-value').click(function () {
        $(this).parent().focus().toggleClass('clicked');
    });

    var options = $('.select-options .option');
    options.click(function () {
        var wrap = $(this).parents('.select-wrap');
        var selectValue = wrap.find('.select-value');
        wrap.removeClass('clicked');
        selectValue.text($(this).text())
        wrap.find('select').val($(this).attr('data-value')).change();
        $('.option', wrap).removeClass('selected');
        $(this).addClass('selected');
        if ($(this).attr('data-default')) {
            selectValue.removeClass('selected');
        }
        else {
            selectValue.addClass('selected');
        }
    });

    $('.select-wrap').attr('tabindex', '1').focusout(function () {
        $(this).removeClass('clicked');
    });

    //find option by pressed keys
    var typingTimer;
    var doneTypingInterval = 700;
    var needle = '';

    $(".select-wrap").keypress(function (e) {
        needle += e.key;
        clearTimeout(typingTimer);
        selectWrap = $(this);

        typingTimer = setTimeout(function () {
            doneTyping(selectWrap);
        }, doneTypingInterval);
    });

    function doneTyping(selectWrap) {
        var opt = $('.option', selectWrap).filter(function () {
            var text = $(this).text().toLowerCase().replace(/\s/g, '');
            needle = needle.replace(/\s/g, '');
            return text.indexOf(needle) === 0;
        });
        if (opt.length > 0) {
            selectWrap.find('select-options').mCustomScrollbar('scrollTo', opt.eq(0).position().top);
            opt.eq(0).click();
        }
        needle = '';
    }
}

function initItemCarousels() {
    var carousels = $('.item-carousel');

    carousels.each(function () {
        var wrap = $(this).closest('.carousel-wrap');
        var owl = $(this).addClass('owl-carousel').owlCarousel({
            items: 6,
            loop: true,
            controls: true,
            dots: false,
            stagePadding: 30,
            autoplay: true,
            autoplayTimeout: 3000,
            responsive: {
                1: {
                    items: 1,
                },
                360: {
                    items: 2,
                },
                600: {
                    items: 3,
                },
                767: {
                    items: 4,
                },
                991: {
                    items: 5,
                },
                1100: {
                    items: 6,
                }
            }
        });

        wrap.find('.c-control').click(function () {
            if ($(this).hasClass('carousel-prev')) {
                owl.trigger('prev.owl.carousel');
            } else {
                owl.trigger('next.owl.carousel');
            }
            return false;
        });
    });
}

function initLayout() {
    $('.useful-links').each(function () {
        var contents = $('.content', $(this));
        var links = $('.links', $(this));
        var tallestContentHeight = 0;
        var tallestLinkHeight = 0;

        contents.attr('style', '');
        links.attr('style', '');

        if ($(window).width() > 767) {
            setTimeout(function () {
                contents.each(function () {
                    tallestContentHeight = tallestContentHeight > $(this).outerHeight() ? tallestContentHeight : $(this).outerHeight();
                });

                links.each(function () {
                    tallestLinkHeight = tallestLinkHeight > $(this).outerHeight() ? tallestLinkHeight : $(this).outerHeight();
                });

                contents.css({
                    'min-height': tallestContentHeight
                });
                links.css({
                    'min-height': tallestLinkHeight
                });
            });
        }
    });
}

function init_map() {
    if (typeof google == 'undefined') {
        return;
    }
    var gm = google.maps;
    var mapElem = document.getElementById("map");

    if (mapElem.length < 1) {
        return;
    }

    if($(mapElem).children().length > 0){
        return;
    }

    map = new gm.Map(mapElem, {
        zoom: mapParams.zoom,
        center: new gm.LatLng(mapParams.lat, mapParams.lng),
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
    });

    map.scrollwheel = false;
    var firstInfoBubble = null;

    for (var i in mapParams.markers) {
        if (mapParams.markers.hasOwnProperty(i)) {
            var marker = mapParams.markers[i];
            var m = new gm.Marker({
                position: new gm.LatLng(marker.lat, marker.lng),
                map: map,
                icon: marker.icon
            });

            newlong = m.getPosition().lng() + (3 * Math.pow(2, (21 - map.getZoom())));

            infoBubble = new InfoBubble({
                map: map,
                content: marker.content,
                shadowStyle: 0,
                padding: 0,
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 4,
                arrowSize: 0,
                borderWidth: 0,
                disableAutoPan: true,
                hideCloseButton: true,
                arrowPosition: '0',
                backgroundClassName: 'phoney',
                arrowStyle: 0,
                height: 'auto',
                maxWidth: 350
            });

            infoBubble.setBubbleOffset('50%', '100%');

            firstInfoBubble = firstInfoBubble ? firstInfoBubble : infoBubble;

            google.maps.event.addListener(m, 'click', function () {
                if (!infoBubble.isOpen()) {
                    infoBubble.open(map, m);
                } else {
                    infoBubble.close();
                }
            });
        }
    }

    google.maps.event.addEventListener(window, 'load', function () {
        firstInfoBubble.open(map, m);

        setTimeout(function () {
            $('.phoney').each(function () {
                var parent = $(this).parent();
                var scH = parent.get(0).scrollHeight;
                var h = parent.height()

                if (scH > h) {
                    if (scH > infoBubble.maxHeight) {
                        parent.css({
                            height: infoBubble.maxHeight,
                            'overflow':'visible'
                        });

                    } else {
                        parent.css({
                            height: scH,
                            'overflow':'visible'
                        });
                    }
                }
            });
        });
        setTimeout(function () {
            firstInfoBubble.draw();
        });
    });
}

